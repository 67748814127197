
import Component from 'vue-class-component';
import { PropSync, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import service from '@/services/app-http-client';
import PaymentModal from '@/modules/organisation/components/Pricing.vue';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import notificationsDropdown from '@/components/notifications.vue';
import userModule from '@/store/modules/user/user.module';
import { Loading } from '@/modules/auth/store/auth.service';
import Routes from '@/shared/constants/routes';
import VueTour1 from '@/components/v-tour/VueTour1.vue';
import ToastificationContent from '@/app/components/toastification/ToastificationContent.vue';
import { mapState } from 'vuex';
import { appModule } from '@/store/modules/app.module';
import RoleType from '../../../shared/constants/roles';
import { Auth } from '@/store/modules/auth.module';
import { INotificationDto } from '../../models/user/user.dto';
@Component({
  components: {
    PaymentModal,
    notificationsDropdown,
    VueTour1,
    ToastificationContent,
  },
  computed: {
    ...mapState('app', ['darkMode']),
  },
})
export default class HorizontalNavMenu extends Vue {
  @PropSync('active') activeSync!: string;

  public darkMode!: boolean;

  ORGANISATION_DASHBOARD = Routes.ORGANISATION_DASHBOARD;

  ORGANISATION_ACCOMMODATIONS = Routes.ORGANISATION_ACCOMMODATIONS;

  ORGANISATION_BOOKING = Routes.ORGANISATION_BOOKING;

  ORGANISATION_ACCOUNT = Routes.ORGANISATION_ACCOUNT;

  GUEST_DASHBOARD_ROUTE = Routes.GUEST_DASHBOARD_ROUTE;

  ORGANISATION_MEMBERS = Routes.ORGANISATION_MEMBERS;

  ORGANISATION_BILLING = Routes.ORGANISATION_BILLING;

  ORGANISATION_SUBSCRIPTION = Routes.ORGANISATION_SUBSCRIPTION;

  USER_PROFILE_ROUTE = Routes.USER_PROFILE_ROUTE;

  HELP_CENTER = Routes.HELP_CENTER;

  navDropdown = false;

  notificationsDropdown = false;

  isOpen = false;

  menuOpen = false;

  isActive = false;

  activeRole = 'organisation';

  bannerOpen = JSON.parse(localStorage.getItem('bannerOpen') as string) ?? true;

  get alertBannerCnt(): string {
    // return contentModule.alertBannerCnt;
    return 'agasdga';
  }

  get currentRoute(): string {
    return this.$route.path;
  }

  handleRoute(path: any) {
    if (this.$route.path != path) {
      this.menuOpen = false;
      return this.$router.push(path);
    }
    return;
  }

  goToBilling(path: any) {
    if (userModule.member[0].role.role === 'member') {
      return this.showPaymentModal();
    }
    return this.$router.push(path);
  }

  goToSubscription(path: any) {
    if (userModule.member[0].role.role === 'member') {
      return this.showPaymentModal();
    }
    return this.$router.push(path);
  }

  showPaymentModal() {
    tenantModule.setShowPricing(true);
  }

  showToast(variant: any, position: any, icon: any) {
    this.$swal({ title: 'Success', icon: 'error' });

    this.$toast(
      {
        component: ToastificationContent,
        props: {
          title: 'Notification',
          text: '👋 Chocolate oat cake jelly oat cake candy jelly beans pastry.',
          icon,
          variant,
        },
      },
      {
        position,
      },
    );
  }

  closeBanner() {
    this.bannerOpen = false;
    localStorage.setItem('bannerOpen', JSON.stringify(false));
  }

  @Watch('active')
  navigateTo(): void {
    if (this.$route.path !== this.activeSync) {
      this.$router.push({ path: this.activeSync });
    }
  }

  closeMenu(): void {
    this.navDropdown = false;
    this.notificationsDropdown = false;
  }

  get themeText() {
    if (this.darkMode) {
      return 'Light mode';
    } else return 'Dark mode';
  }

  setActiveRole() {
    if (this.activeRole === 'organisation') {
      this.activeRole = 'guest';
      this.$router.push({ path: this.activeSync });

      return this.activeRole;
    }
    this.activeRole = 'organisation';
    return this.activeRole;
  }

  get role(): string[] | undefined {
    if (userModule.role[0]) {
      return userModule.role.map((role: any) => role.roleName);
    }
    return userModule.role;
  }

  get authenticated() {
    return Auth.isAuthenticated;
  }

  get accommodationLength(): boolean {
    return tenantModule.accommodations?.length === 0 || false;
  }

  get credits(): number | undefined {
    return tenantModule.tenant?.subscription?.checkIns;
  }

  get trialActive() {
    return tenantModule.tenant.subscription?.trialActive;
  }

  get accountActive() {
    return tenantModule.tenant.subscription?.isActive;
  }

  async test(): Promise<void> {
    const test = service.get('organisation/subscription/1');
  }

  get profileImage(): string {
    return 'https://w7.pngwing.com/pngs/893/926/png-transparent-silhouette-user-icon-profile-silhouette-silhouette-avatar-profile-silhouette.png';
  }

  get notificationCount(): number | undefined {
    // eslint-disable-next-line camelcase
    return userModule.user?.notifications?.filter((ntf: { is_read: any }) => !ntf.is_read).length;
  }

  get unreadNotifications(): INotificationDto[] | undefined {
    return userModule.user?.notifications;
  }

  get navbarColor(): string {
    return this.$store.state.theme === 'dark' ? '#fff' : '#fff';
  }

  get tourActive() {
    return appModule.tourActive;
  }

  toChat() {
    this.$router.push('/organisation/chat');
  }

  swapTheme() {
    appModule.updateTheme(!appModule.darkMode);
  }
  created() {
    const NavVTourSee = localStorage.getItem('NavVTourSee');
    if (NavVTourSee == null) {
      this.navDropdown = true;
    }
  }
  @Loading
  async logout(): Promise<void> {
    const NavVTourSeen = localStorage.getItem('NavVTourSee');
    localStorage.clear();
    if (NavVTourSeen != null) {
      /*       localStorage.setItem('NavVTourSeen', NavVTourSeen);
       */ window.localStorage.setItem('NavVTourSee', 'false');
      window.localStorage.setItem('BookingVTourSee', 'false');
      window.localStorage.setItem('AccommodationVTourSee', 'false');
    }
    this.$router.replace('/');
    await userModule.logoutUser();
  }

  roleState() {
    return RoleType.Guest;
  }
}
