
import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import HNavMenu from '@/app/components/header/TheNavMenu.vue';
import TheFooter from '@/shared/layouts/footer/TheFooter.vue';
import themeConfig from '@/app/config/themeConfig';
import { mapState } from 'vuex';
import { appModule } from '@/store/modules/app.module';

import AlertBanner from '@/app/components/alert-banner/AlertBanner.vue';
import Routes from '@/shared/constants/routes';

@Component({
  components: {
    TheFooter,
    HNavMenu,
    AlertBanner,
  },
  data() {
    return {
      footerType: themeConfig.footerType || 'static',
      hideScrollToTop: themeConfig.hideScrollToTop,
      isNavbarDark: false,
      navbarColor: themeConfig.navbarColor || '#fff',
      navbarType: 'sticky',

      routeTitle: this.$route.meta?.title,
    };
  },

  computed: {
    ...mapState('app', ['windowWidth', 'verticalNavMenuWidth', 'mainLayoutType', 'layoutTypeClass', 'theme']),
  },
  //   watch: {
  //     $route() {
  //       this.routeTitle = this.$route.meta.title;
  //     },
  //   },
})
export default class Main extends Vue {
  routerTransition = themeConfig.routerTransition || 'slide-fade';

  footerType!: string;

  navbarColor!: string;

  isNavbarDark!: boolean;

  routeTitle!: string;

  mainLayoutType!: string;

  windowWidth!: number;

  navbarType!: string;

  layoutTypeClass!: string;

  active = Routes.ORGANISATION_DASHBOARD;

  activeSidebar = false;

  get footerClasses() {
    return {
      'footer-hidden': this.footerType === 'hidden',
      'footer-sticky': this.footerType === 'sticky',
      'footer-static': this.footerType === 'static',
    };
  }

  @Watch(`$route`)
  pageTitle(route: any) {
    this.routeTitle = route.meta.title;
  }

  get isAppPage() {
    return this.$route.meta?.noScroll;
  }

  created() {
    const color = '#fff';
    this.updateNavbarColor(color);
    // this.setNavMenuVisibility(this.$store.state.mainLayoutType);
  }

  changeRouteTitle(title: string): void {
    this.routeTitle = title;
  }

  get theme(): boolean {
    return appModule.darkMode;
  }

  updateNavbarColor(val: string) {
    this.navbarColor = val;
    if (val === '#fff') this.isNavbarDark = false;
    else this.isNavbarDark = true;
  }
}
