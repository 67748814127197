











































export default {
  name: 'TheFooter',
  props: {
    classes: {
      type: String,
      default: () => '',
    },
  },
};
