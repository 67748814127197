




































import { Component, Vue, Prop } from 'vue-property-decorator';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { INotificationDto } from '../app/entities/user/IUserDto';

interface DeconstructedDate {
  hr?: number;
  min?: number;
  sec?: number;
}

@Component({
  components: {
    VuePerfectScrollbar,
  },
})
export default class Notifications extends Vue {
  cleared = false;
  // unreadNotifications: Array<object> = [
  //   {
  //     index: 0,
  //     title: 'New Message',
  //     msg: 'Are your going to meet me tonight?',
  //     icon: 'MessageSquareIcon',
  //     time: this.randomDate({ sec: 10 }),
  //     category: 'primary',
  //   },
  //   {
  //     index: 1,
  //     title: 'New Order Recieved',
  //     msg: 'You got new order of goods.',
  //     icon: 'PackageIcon',
  //     time: this.randomDate({ sec: 40 }),
  //     category: 'success',
  //   },
  //   {
  //     index: 2,
  //     title: 'Server Limit Reached!',
  //     msg: 'Server have 99% CPU usage.',
  //     icon: 'AlertOctagonIcon',
  //     time: this.randomDate({ min: 1 }),
  //     category: 'danger',
  //   },
  //   {
  //     index: 3,
  //     title: 'New Mail From Peter',
  //     msg: 'Cake sesame snaps cupcake',
  //     icon: 'MailIcon',
  //     time: this.randomDate({ min: 6 }),
  //     category: 'primary',
  //   },
  //   {
  //     index: 4,
  //     title: "Bruce's Party",
  //     msg: 'Chocolate cake oat cake tiramisu',
  //     icon: 'CalendarIcon',
  //     time: this.randomDate({ hr: 2 }),
  //     category: 'warning',
  //   },
  // ];

  @Prop()
  unreadNotifications!: Array<INotificationDto>;

  @Prop()
  bellCount!: number;

  settings = {
    maxScrollbarLength: 60,
    wheelSpeed: 0.6,
  };

  get scrollbarTag(): string {
    return this.$store.getters.scrollbarTag;
  }

  markRead(): void {
    this.bellCount = 0;
    this.cleared = true;
  }

  elapsedTime(startTime: number): string {
    const x: Date = new Date(startTime);
    const now: Date = new Date();
    let timeDiff = +now - +x;
    timeDiff /= 1000;

    const seconds = Math.round(timeDiff);
    timeDiff = Math.floor(timeDiff / 60);

    const minutes = Math.round(timeDiff % 60);
    timeDiff = Math.floor(timeDiff / 60);

    const hours = Math.round(timeDiff % 24);
    timeDiff = Math.floor(timeDiff / 24);

    const days = Math.round(timeDiff % 365);
    timeDiff = Math.floor(timeDiff / 365);

    const years = timeDiff;

    if (years > 0) {
      return `${years + (years > 1 ? ' Years ' : ' Year ')}ago`;
    }
    if (days > 0) {
      return `${days + (days > 1 ? ' Days ' : ' Day ')}ago`;
    }
    if (hours > 0) {
      return `${hours + (hours > 1 ? ' Hrs ' : ' Hour ')}ago`;
    }
    if (minutes > 0) {
      return `${minutes + (minutes > 1 ? ' Mins ' : ' Min ')}ago`;
    }
    if (seconds > 0) {
      return seconds + (seconds > 1 ? ' sec ago' : 'just now');
    }

    return 'Just Now';
  }

  // Method for creating dummy notification time
  randomDate({ hr, min, sec }: DeconstructedDate) {
    const date = new Date();

    if (hr) date.setHours(date.getHours() - hr);
    if (min) date.setMinutes(date.getMinutes() - min);
    if (sec) date.setSeconds(date.getSeconds() - sec);

    return date;
  }
}
